<template>
  <v-container class="mx-lg-6 mt-7">
    <v-row>
      <v-spacer/>
      <v-col sm="11" xl="9">

        <h1>Erklärung zur Barrierefreiheit</h1>

        <p>
          Als öffentliche Stelle im Sinne der Richtlinie (EU) 2016/2102 sind wir bemüht, unsere Websites und mobilen
          Anwendungen im Einklang mit den Bestimmungen des Behindertengleichstellungsgesetzes (BGG NRW) sowie der
          Barrierefreien-Informationstechnik-Verordnung NRW (BITV NRW) zur Umsetzung der Richtlinie (EU) 2016/2102
          barrierefrei zugänglich zu machen.</p>

        <h2>Stand der Vereinbarkeit mit den Anforderungen</h2>
        <p>
          Die Anforderungen der Barrierefreiheit ergeben sich aus Paragraphen 3 Absätze 1 bis 4 und Paragraph 4 der
          Barrierefreien-Informationstechnik-Verordnung NRW (BITV NRW), die auf der Grundlage von § 10e
          Behindertengleichstellungsgesetz (BGG NRW) erlassen wurde.</p>


        <p>Die Überprüfung der Einhaltung der Anforderungen beruht auf einer am 28.06.2022 durchgeführten
          Selbstbewertung.</p>

        <h2>
          Datum der Erstellung bzw. der letzten Aktualisierung der Erklärung
        </h2>
        <p>
          Diese Erklärung wurde am 28.06.2022 erstellt und zuletzt am 28.06.2022 aktualisiert. </p>
        <h2>Nicht barrierefreie Inhalte</h2>

        <p>
          Die Nutzung einer Karte ist nicht vollständig barrierefrei umsetzbar. Für die Festlegung des Antragsgebietes
          wird daher alternativ ein <a :href="host +'/bestellformular'" target="_blank">Bestellformular ohne Kartennutzung</a> angeboten.
        </p>
        <h2>Barrieren melden: Kontakt zu den Feedback Ansprechpartnern</h2>
        <p>
          Sie möchten uns bestehende Barrieren mitteilen oder Informationen zur Umsetzung der Barrierefreiheit erfragen?
          Für
          Ihr Feedback sowie alle weiteren Informationen nutzen Sie bitte folgendes Meldeformular:
          <a target="_blank"
             href="https://bitv-meldeformular.nrw.de/174568e8545019377291fb2a9b69b48c">
            Portal Vermessungsunterlagen NRW - Meldeformular Barrierefreiheit</a></p>
        <h2>Schlichtungsverfahren</h2>

        <p>
          Wenn auch nach Ihrem Feedback an den oben genannten Kontakt keine zufriedenstellende Lösung gefunden wurde,
          können
          Sie sich an die Schlichtungsstelle nach § 10d Behindertengleichstellungsgesetz NRW (BGG NRW) wenden. Die
          Schlichtungsstelle BGG NRW hat die Aufgabe, bei Konflikten zum Thema Barrierefreiheit zwischen Menschen mit
          Behinderungen und öffentlichen Stellen des Bundes eine außergerichtliche Streitbeilegung zu unterstützen. Das
          Schlichtungsverfahren ist kostenlos. Es muss kein Rechtsbeistand eingeschaltet werden. Weitere Informationen
          zum
          Schlichtungsverfahren und den Möglichkeiten der Antragstellung erhalten Sie unter:
          <a href="https://www.mags.nrw/ombudsstelle-barrierefreie-informationstechnik" target="_blank"
             rel="noopener noreferrer">www.mags.nrw/ombudsstelle-barrierefreie-informationstechnik</a>
        </p>
        <p>
          Direkt kontaktieren können Sie die Schlichtungsstelle BGG NRW unter: <a
            href="mailto:ombudsstelle-barrierefreie-it@mags.nrw.de">ombudsstelle-barrierefreie-it@mags.nrw.de</a>

        </p></v-col>
      <v-spacer/>
    </v-row>
  </v-container>
</template>

<script>

export default {

  components: {},
  data() {
    return {
      host: window.location.origin
    }
  },
  methods: {},
  mounted() {

  }
  ,


}
</script>
